<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Solicitudes Transporte</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Tep</li>
                  <li class="breadcrumb-item active">Internos</li>
                  <li class="breadcrumb-item active">Operaciones</li>
                  <li class="breadcrumb-item active">Solicitud Transporte</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2">
                <div class="row">
                  <div class="col-md-12">
                    <div class="btn-group float-right">
                      <!-- SE LLAMAN BOTONES ACA -->
                      <button
                        type="button"
                        class="btn btn-success"
                        data-toggle="modal"
                        data-target="#modal-form-Tepsolicitud-export"
                      >
                        <i class="far fa-file-excel"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body p-0 table-responsive">
                <table
                  id="users"
                  class="
                    table table-bordered table-striped table-hover table-sm
                  "
                >
                  <thead>
                    <tr>
                      <th>Servicios</th>
                      <th>
                        #<input
                          type="number"
                          class="form-control form-control-sm"
                          v-model="filtros.id_solicitud"
                          @keyup="getIndex()"
                        />
                      </th>
                      <th>
                        Origen<input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.origen"
                          @keyup="getIndex()"
                        />
                      </th>
                      <th>
                        Destino
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.destino"
                          @keyup="getIndex()"
                        />
                      </th>
                      <th>
                        Usuario Solicita<input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.usuario"
                          @keyup="getIndex()"
                        />
                      </th>
                      <th>
                        Tipo de Solicitud
                        <select
                          class="form-control form-control-sm"
                          v-model="filtros.tipo_solicitud"
                          @change="getIndex()"
                        >
                          <option value="">Seleccione...</option>
                          <option
                            v-for="tipo_solicitud in listasForms.tipo_solicitud"
                            :key="tipo_solicitud.numeracion"
                            :value="tipo_solicitud.numeracion"
                          >
                            {{ tipo_solicitud.descripcion }}
                          </option>
                        </select>
                      </th>
                      <th>
                        Fecha Inicio
                        <input
                          type="date"
                          class="form-control form-control-sm"
                          v-model="filtros.fecha_inicial"
                          @change="getIndex()"
                        />
                      </th>
                      <th>
                        Fecha Final
                        <input
                          type="date"
                          class="form-control form-control-sm"
                          v-model="filtros.fecha_final"
                          @change="getIndex()"
                        />
                      </th>
                      <th>
                        Conductor
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.conductor"
                          @keyup="getIndex()"
                        />
                      </th>
                      <th>
                        Vehiculo
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.vehiculo"
                          @keyup="getIndex()"
                        />
                      </th>
                      <th>Odometro Inicial</th>
                      <th>Odometro Final</th>
                      <th>
                        Estado
                        <select
                          class="form-control form-control-sm"
                          v-model="filtros.estado"
                          @change="getIndex()"
                        >
                          <option value="">Seleccione...</option>
                          <option
                            v-for="estado in listasForms.estados"
                            :key="estado.numeracion"
                            :value="estado.numeracion"
                          >
                            {{ estado.descripcion }}
                          </option>
                        </select>
                      </th>
                      <th>
                        Tipo de operacion
                        <select
                          class="form-control form-control-sm"
                          v-model="filtros.tipo_operacion"
                          @change="getIndex()"
                        >
                          <option value="">Seleccione...</option>
                          <option
                            v-for="tipo_operacion in listasForms.tipo_operacion"
                            :key="tipo_operacion.numeracion"
                            :value="tipo_operacion.numeracion"
                          >
                            {{ tipo_operacion.descripcion }}
                          </option>
                        </select>
                      </th>
                      <th>Accion</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in solicitudes.data" :key="item.id">
                      <td>
                        <div class="btn-group dropright">
                          <button
                            type="button"
                            class="btn btn-dark btn-sm"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            :disabled="
                              !(
                                $store.getters.can('tep.solicitudes.asignar') &&
                                item.estado == 1 &&
                                item.tipo_transporte == 2
                              ) &&
                                !(
                                  item.nEstadoOperacion == 'Renting' &&
                                  item.estado_inspeccion == null
                                ) &&
                                !(
                                  item.estado == 2 && item.tipo_transporte == 2
                                ) &&
                                !(
                                  item.estado === 2 && item.tipo_transporte == 1
                                ) &&
                                !(
                                  $store.getters.can(
                                    'tep.solicitudes.cancelar'
                                  ) &&
                                  (item.estado == 1 || item.estado == 6)
                                ) &&
                                !(
                                  $store.getters.can(
                                    'tep.solicitudes.enviarMails'
                                  ) && item.estado == 1
                                )
                            "
                          >
                            <i class="fas fa-align-justify"></i>
                          </button>
                          <div class="dropdown-menu">
                            <a
                              id="botonImp"
                              type="button"
                              class="dropdown-item"
                              style="cursor: pointer"
                              data-toggle="modal"
                              data-target="#modal-form-asignacion-tepsolicitud"
                              v-if="
                                $store.getters.can('tep.solicitudes.asignar') &&
                                  item.estado == 1 &&
                                  item.tipo_transporte == 2
                              "
                              @click="llenarModalAsignacion(item)"
                            >
                              <i class="fas fa-flag-checkered"></i>
                              Asignar Vehículo
                            </a>
                            <a
                              class="dropdown-item"
                              style="cursor: pointer"
                              type="button"
                              data-toggle="modal"
                              v-if="
                                item.nEstadoOperacion == 'Renting' &&
                                  item.estado_inspeccion == null
                              "
                              data-target="#modal-form-inspeccion-tepSolicitud"
                              @click="llenarModalInspeccion(item, 1)"
                            >
                              <i class="fas fa-binoculars"></i>
                              Diligenciar Inspección
                            </a>
                            <a
                              id="botonImp"
                              type="button"
                              class="dropdown-item"
                              style="cursor: pointer"
                              data-toggle="modal"
                              data-target="#modal-form-asignar-tepsolicitud"
                              v-if="
                                item.estado == 2 && item.tipo_transporte == 2
                              "
                              @click="llenarModalAsignar(item)"
                            >
                              <i class="fas fa-eye"></i>
                              Asignar Solicitud
                            </a>
                            <a
                              class="dropdown-item"
                              style="cursor: pointer"
                              id="botonImp"
                              type="button"
                              data-toggle="modal"
                              data-target="#modal-form-asignar-otra-tepsolicitud"
                              v-if="
                                (item.estado === 2 || item.estado === 6) &&
                                  item.tipo_transporte == 1
                              "
                              @click="llenarModalAsignarAOtraSolicitud(item)"
                            >
                              <i class="fas fa-plus"></i>
                              Asignar a Otra Solicitud
                            </a>
                            <a
                              class="dropdown-item"
                              style="cursor: pointer"
                              id="botonImp"
                              type="button"
                              data-toggle="modal"
                              data-target="#modal-form-cancelar-viaje"
                              v-if="
                                $store.getters.can(
                                  'tep.solicitudes.cancelar'
                                ) &&
                                  (item.estado == 1 ||
                                    item.estado == 2 ||
                                    item.estado == 6)
                              "
                              @click="CancelarSolicitud(item)"
                            >
                              <i class="fas fa-backspace"></i>
                              Cancelar Solicitud
                            </a>
                            <a
                              class="dropdown-item"
                              style="cursor: pointer"
                              id="botonImp"
                              type="button"
                              v-if="
                                $store.getters.can(
                                  'tep.solicitudes.enviarMails'
                                ) && item.estado == 1
                              "
                              @click="enviarMailSolicitud(item)"
                            >
                              <i class="fas fa-envelope"></i>
                              Enviar Mail Solicitud
                            </a>
                          </div>
                        </div>
                      </td>
                      <td>
                        <button
                          id="botonImp"
                          type="button"
                          class="dropdown-item"
                          style="cursor: pointer"
                          data-toggle="modal"
                          data-target="#modal-form-detalle-tepsolicitud"
                          v-if="$store.getters.can('tep.solicitudes.detalle')"
                          @click="llenarModal(item)"
                        >
                          {{ item.id }}
                        </button>
                      </td>
                      <td>{{ item.direccion_origen }}</td>
                      <td>{{ item.direccion_destino }}</td>
                      <td class="text-nowrap">
                        <div v-for="usuarios in item.users" :key="usuarios.id">
                          {{ usuarios.name }}
                        </div>
                      </td>
                      <td class="text-center">
                        <span
                          class="badge"
                          :class="
                            item.tipo_transporte == 1
                              ? 'bg-navy'
                              : item.tipo_transporte == 2
                              ? 'bg-info'
                              : 'bg-secondary'
                          "
                        >
                          {{ item.nTipoSolicitud }}
                        </span>
                      </td>
                      <td class="text-nowrap">
                        {{ item.fecha_hora_inicio_servicio }}
                      </td>
                      <td class="text-nowrap">
                        {{ item.fecha_hora_fin_servicio }}
                      </td>
                      <td>
                        {{
                          item.conductor
                            ? `${item.conductor.nombres} ${item.conductor.apellidos}`
                            : ""
                        }}
                      </td>
                      <td>{{ item.vehiculo ? item.vehiculo.placa : "" }}</td>
                      <td>{{ item.viaje ? item.viaje.odometro_ini : "" }}</td>
                      <td>{{ item.viaje ? item.viaje.odometro_fin : "" }}</td>
                      <td class="text-center">
                        <span
                          class="badge"
                          :class="
                            item.estado == 1
                              ? 'bg-warning'
                              : item.estado == 2
                              ? 'bg-lime'
                              : item.estado == 3
                              ? 'bg-info'
                              : item.estado == 4
                              ? 'bg-danger'
                              : item.estado == 5
                              ? 'bg-primary'
                              : item.estado == 6
                              ? 'bg-success'
                              : item.estado == 7
                              ? 'bg-danger'
                              : item.estado == 8
                              ? 'bg-primary'
                              : 'bg-secondary'
                          "
                        >
                          {{
                            item.estado != 8
                              ? item.nEstado
                              : `${item.nEstado} / ${item.tep_solicitud_padre_id}`
                          }}
                        </span>
                      </td>
                      <td class="text-center">
                        <span
                          class="badge"
                          v-if="item.tipo_operacion"
                          :class="
                            item.tipo_operacion == 1
                              ? 'badge-info'
                              : item.tipo_operacion == 2
                              ? 'badge-secondary'
                              : item.tipo_operacion == 3
                              ? 'badge-primary'
                              : 'badge-light'
                          "
                        >
                          {{ item.nEstadoOperacion }}
                        </span>
                      </td>
                      <td style="width: 50px" class="text-right">
                        <div class="btn-group float-right">
                          <button
                            type="button"
                            class="btn btn-sm bg-danger"
                            @click="verPDF(item.id)"
                          >
                            <i class="fas fa-file-pdf"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="card-footer">
                <div class="float-left" v-if="solicitudes.total">
                  <p>
                    Mostrando del <b>{{ solicitudes.from }}</b> al
                    <b>{{ solicitudes.to }}</b> de un total:
                    <b>{{ solicitudes.total }}</b> Registros
                  </p>
                </div>
                <div class="float-left" v-else>
                  <p>
                    <span class="badge badge-danger">
                      No hay registros para mostrar
                    </span>
                  </p>
                </div>
                <pagination
                  :data="solicitudes"
                  @pagination-change-page="getIndex"
                  :limit="10"
                  class="float-right"
                ></pagination>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <TepSolicitudDetalle ref="TepSolicitudDetalle" />
    <TepSolicitudCancelar ref="TepSolicitudCancelar" />
    <TepSolicitudAsignacion ref="TepSolicitudAsignacion" />
    <TepSolicitudAsignarAOtraSolicitud
      ref="TepSolicitudAsignarAOtraSolicitud"
    />
    <TepSolicitudExport ref="TepSolicitudExport" />
    <TepSolicitudAsignarPadre ref="TepSolicitudAsignarPadre" />
  </div>
</template>

<script>
import axios from "axios";
import Loading from "../../../../components/Loading";
import pagination from "laravel-vue-pagination";
import TepSolicitudDetalle from "./TepSolicitudDetalle";
import TepSolicitudCancelar from "./TepSolicitudCancelar";
import TepSolicitudAsignacion from "./TepSolicitudAsignacion";
import TepSolicitudAsignarAOtraSolicitud from "./TepSolicitudAsignarAOtraSolicitud";
import TepSolicitudAsignarPadre from "./TepSolicitudAsignarPadre";
import { required, helpers } from "vuelidate/lib/validators";
const timeFormat = helpers.regex(
  "timeFormat",
  /^([0-1]?[0-9]|2[0-3]):([0-5][0-9])(:[0-5][0-9])?$/
);
import TepSolicitudExport from "./TepSolicitudExport";
export default {
  name: "TepSolicitudIndex",
  components: {
    Loading,
    pagination,
    TepSolicitudDetalle,
    TepSolicitudCancelar,
    TepSolicitudAsignacion,
    TepSolicitudAsignarAOtraSolicitud,
    TepSolicitudExport,
    TepSolicitudAsignarPadre,
  },
  data() {
    return {
      cargando: false,
      filtros: {
        id_solicitud: null,
        usuario: null,
        origen: null,
        estado: null,
        tipo_operacion: null,
        destino: null,
        conductor: null,
        vehiculo: null,
        fecha_inicial: null,
        fecha_final: null,
        tipo_solicitud: null,
      },
      solicitudes: {},
      listasForms: {
        estados: [],
        tipo_operacion: [],
        tipo_solicitud: [],
      },
    };
  },
  validations: {
    filtros: {
      hora_inicial: {
        timeFormat,
      },
      hora_final: {
        timeFormat,
      },
    },
  },
  methods: {
    getIndex(page = 1) {
      this.cargando = true;
      axios
        .get("/api/tep/SolicitudesTransporte?page=" + page, {
          params: this.filtros,
        })
        .then((response) => {
          this.solicitudes = response.data;
          this.cargando = false;
        });
    },
    getEstados() {
      axios.get("/api/lista/84").then((response) => {
        this.listasForms.estados = response.data;
      });
    },
    getTipoOperacion() {
      axios.get("/api/lista/80").then((response) => {
        this.listasForms.tipo_operacion = response.data;
      });
    },
    getTipoSolicitud() {
      axios.get("/api/lista/88").then((response) => {
        this.listasForms.tipo_solicitud = response.data;
      });
    },
    llenarModal(item) {
      if (this.$store.getters.can("tep.solicitudes.detalle")) {
        this.$refs.TepSolicitudDetalle.llenar_modal(item);
      }
    },
    llenarModalAsignar(item) {
      if (this.$store.getters.can("tep.solicitudes.index")) {
        this.$refs.TepSolicitudAsignarPadre.llenar_modal(item);
      }
    },
    llenarModalAsignarAOtraSolicitud(item) {
      if (this.$store.getters.can("tep.solicitudes.index")) {
        this.$refs.TepSolicitudAsignarAOtraSolicitud.llenar_modal(item);
      }
    },
    llenarModalAsignacion(item) {
      if (this.$store.getters.can("tep.solicitudes.detalle")) {
        this.$refs.TepSolicitudAsignacion.llenar_modal(item);
      }
    },

    CancelarSolicitud(item) {
      if (this.$store.getters.can("tep.solicitudes.cancelar")) {
        this.$refs.TepSolicitudCancelar.llenar_modal(item);
      }
    },

    enviarMailSolicitud(item) {
      this.cargando = true;
      axios({
        method: "POST",
        url: "/api/tep/SolicitudesTransporte/enviarMailSolicitud",
        data: {
          data: JSON.stringify(item),
        },
      })
        .then(() => {
          this.cargando = false;
          this.$swal({
            icon: "success",
            title: "Correo enviado exitosamente...",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
        })
        .catch((e) => {
          this.cargando = false;
          this.$swal({
            icon: "error",
            title: "Ocurrió un error, vuelva a intentarlo..." + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },
    getMsg() {
      if (this.$route.params.msg) {
        this.$swal({
          icon: "success",
          title: this.$route.params.msg,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        });
      }
    },
    validaFechas() {
      if (this.filtros.fecha_inicial && this.filtros.fecha_final) {
        var fecha_menor = new Date(this.filtros.fecha_inicial);
        var fecha_mayor = new Date(this.filtros.fecha_final);
        // Se valida que la fecha inicial sea menor que la fecha final
        if (fecha_menor > fecha_mayor) {
          this.filtros.fecha_final = null;
          this.$swal({
            icon: "error",
            title: `La fecha inicial no puede ser mayor a la fecha final...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
    },
    verPDF(id_solicitud) {
      this.cargando = true;
      axios({
        method: "get",
        url: "/api/tep/solicitud/pdf/" + id_solicitud,
      })
        .then((response) => {
          let data = response.data;
          this.cargando = false;
          window.open(data.url, "_blank");
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrió un error: " + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },
  },

  mounted() {
    this.getIndex();
    this.getEstados();
    this.getTipoOperacion();
    this.getTipoSolicitud();
    this.getMsg();
  },
};
</script>
