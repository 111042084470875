<template>
  <div>
    <!-- Modal -->
    <div class="modal fade" id="modal-form-cancelar-viaje">
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Cancelar Viaje</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="form-group col-md-12">
                <label for="fecha_fin">Razón Cancelación</label>
                <textarea 
                  v-model="form.razon_cancelacion"
                  style="font-size: 13px"
                  class="form-control form-control-sm"
                  :class="
                    $v.form.razon_cancelacion.$invalid
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                ></textarea>
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-between"
            v-if="!$v.form.$invalid"
          >
            <button
              type="button"
              class="btn btn-primary"
              @click="save()"
            >Cancelar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required } from "vuelidate/lib/validators";
export default {
  name: "TepSolicitudCancelar",
  data() {
    return {
      form: {},
    };
  },
  validations: {
    form: {
      razon_cancelacion: {
        required,
      },
    },
  },
  methods: {
    save() {
      this.$swal({
        title: "Esta seguro de cancelar esta Solicitud?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Cancelar!",
      }).then((result) => {
        if (result.value) {
          this.$parent.cargando = true;
          axios({
            method: "PUT",
            url: "/api/tep/solicitudesTransporte/cancelar",
            data: this.form,
          }).then((response) => {
            if (!response.data.error) {
              this.$parent.getIndex();
              this.$refs.closeModal.click();
              this.$swal({
                icon: "success",
                title: "Se canceló la Solicitud exitosamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 4000,
                timerProgressBar: true,
              });
            } else {
              this.$swal({
                icon: "error",
                title: response.data.error,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
              this.$parent.cargando = false;
            }
          });
        }
      });
    },

    llenar_modal(viaje) {
      this.form.razon_cancelacion = null;
      this.form = viaje;
    },
  },
};
</script>
