<template>
  <div>
    <!-- Modal -->
    <div
      class="modal fade"
      id="modal-form-asignar-tepsolicitud"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
      data-backdrop="static"
      data-keyboard="false"
    >
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Asignar Solicitud</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12">
                <div class="col-md-12">
                  <div class="row">
                    <table
                      id="users"
                      class="
                        table table-bordered table-striped table-hover table-sm
                      "
                    >
                      <thead>
                        <tr>
                          <th>Origen</th>
                          <th>Destino</th>
                          <th>Tipo de vehiculo</th>
                          <th>Cantidad de pasajeros</th>
                          <th>Seleccione...</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item in padre" :key="item.id">
                          <td>
                            {{ item.todo.direccion_origen }}
                          </td>
                          <td>
                            {{ item.todo.direccion_destino }}
                          </td>
                          <td>
                            {{ item.todo.tipo_vehiculo.nombre }}
                          </td>
                          <td>
                            {{ item.pasajero_total }}
                          </td>
                          <td>
                            <a
                              id="botonImp"
                              type="button"
                              class="dropdown-item"
                              style="cursor: pointer"
                              @click="selectSolicitud(item)"
                            >
                              <i class="fas fa-flag-checkered"></i>
                              Asignar solicitud
                            </a>
                          </td>
                          <td></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="modal-footer">
            <div class="row">
              <div class="col-md-3">
                <button
                  type="button"
                  class="btn btn-success"
                  v-if="$store.getters.can('tep.turnos.create')"
                  @click="validarProgrmacion()"
                >
                  <i class="fas fa-check-double col-md-12"></i>
                  <strong> Validar</strong>
                </button>
              </div>
            </div>
          </div> -->
          <div class="modal-footer"></div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "TepSolicitudAsignarPadre",
  components: {},
  data() {
    return {
      flag: null,
      form: {
        vehiculo_id: null,
        fecha_inicial: null,
        hora_inicial: null,
      },
      vehiculo: [],
      data_solicitud: {},
      padre: {},
      tipo_control: null,
    };
  },
  methods: {
    async llenar_modal(item) {
      this.data_solicitud = item;
      let me = this;      
      axios({
        method: "GET",
        url: "/api/tep/solicitudes/getlugares",
        params: item,
      })
        .then(function (response) {
          me.padre = response.data;
        })
        .catch(function (error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },
    selectSolicitud(item) {
      axios({
        method: "POST",
        url: "/api/tep/solicitudesTransporte/asignarSolicitudPadre",
        data: {
          item: item,
          solicitud: this.data_solicitud,
        },
      })
        .then(() => {
          this.cargando = false;
          this.$refs.closeModal.click();
          this.$parent.getIndex();
          this.$swal({
            icon: "success",
            title: "Los datos se guardaron exitosamente...",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          
        })
        .catch((e) => {
          this.cargando = false;
          this.$swal({
            icon: "error",
            title: "Ocurrió un error, vuelva a intentarlo..." + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },
  },
};
</script>
