<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div
      class="modal fade"
      id="modal-form-Tepsolicitud-export"
      style="background-color: #00000082"
    >
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Generar Reporte Solicitudes</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal1"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item">
                <a
                  class="nav-link active"
                  id="tab-Filtros"
                  data-toggle="tab"
                  href="#Filtros"
                  >Filtros
                </a>
              </li>
            </ul>
            <div class="tab-content" id="myTabContent">
              <div class="tab-pane fade active show" id="Filtros">
                <div class="card-body">
                  <div class="form-group row">
                    <label for="id_nomina" class="col-md-5"
                      >Tipo de operacion</label
                    >
                    <select
                      class="form-control form-control-sm"
                      v-model="form.tipo_operacion"
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="tipo_operacion in listasForms.tipo_operacion"
                        :key="tipo_operacion.numeracion"
                        :value="tipo_operacion.numeracion"
                      >
                        {{ tipo_operacion.descripcion }}
                      </option>
                    </select>
                    <label for="id_nomina" class="col-md-5">Estado</label>
                    <select
                      class="form-control form-control-sm"
                      v-model="form.estado"
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="estado in listasForms.estados"
                        :key="estado.numeracion"
                        :value="estado.numeracion"
                      >
                        {{ estado.descripcion }}
                      </option>
                    </select>
                    <label for="id_nomina" class="col-md-5"
                      >Fecha inicial</label
                    >
                    <input
                      type="date"
                      class="form-control form-control-sm"
                      v-model="form.fecha_ini"
                    />
                    <label for="id_nomina" class="col-md-5">Fecha final</label>
                    <input
                      type="date"
                      class="form-control form-control-sm"
                      v-model="form.fecha_fin"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="modal-footer justify-content-between">
            <div>
              <button
                type="button"
                class="btn btn-sm bg-navy"
                @click="generarListadoExcel()"
              >
                Generar Listado
                <i class="fas fa-file-download"> </i>
              </button>
            </div>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Loading from "../../../../components/Loading";
export default {
  name: "TepReporteSolicitudExport",
  components: {
    Loading,
  },
  data() {
    return {
      cargando: false,
      form: {
        estado: null,
        tipo_operacion: null,
        fecha_ini: null,
        fecha_fin: null,
      },
      listasForms: {
        tipo_operacion: [],
        estado: [],
      },
    };
  },

  methods: {
    getEstados() {
      axios.get("/api/lista/84").then((response) => {
        this.listasForms.estados = response.data;
      });
    },
    getTipoOperacion() {
      axios.get("/api/lista/80").then((response) => {
        this.listasForms.tipo_operacion = response.data;
      });
    },

    generarListadoExcel() {
      // this.cargando = true;
      axios({
        method: "POST",
        url: "/api/tep/solicitudes/reporte",
        data: this.form,
      })
        .then((response) => {
          let data = response.data;
          this.cargando = false;
          location.href = data.url;
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },
  },

  async mounted() {
    this.cargando = true;
    this.getEstados();
    this.getTipoOperacion();
    this.cargando = false;
  },
};
</script>
