<template>
  <div>
    <div
      class="modal fade"
      id="modal-form-asignar-otra-tepsolicitud"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
      data-backdrop="static"
      data-keyboard="false"
    >
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Asignar a Otra Solicitud</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <!-- Detalle de la Solicitud -->
            <div class="row">
              <div class="col-md-12">
                <div
                  class="position-relative p-3 m-2 bg-light"
                  style="font-size: 0.85em"
                >
                  <div class="ribbon-wrapper ribbon-lg">
                    <div
                      class="ribbon"
                      :class="
                        data_solicitud.estado == 1
                          ? 'badge-warning'
                          : data_solicitud.estado == 2
                          ? 'badge-success'
                          : data_solicitud.estado == 3
                          ? 'badge-primary'
                          : data_solicitud.estado == 4
                          ? 'badge-danger'
                          : data_solicitud.estado == 5
                          ? 'badge-primary'
                          : data_solicitud.estado == 6
                          ? 'badge-success'
                          : 'badge-danger'
                      "
                    >
                      <small>{{ data_solicitud.nEstado }}</small>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-1">
                      <div class="description-block">
                        <h5 class="description-header">
                          {{ data_solicitud.id }}
                        </h5>
                        <span class="description-text text-secondary"
                          ># Solicitud</span
                        >
                      </div>
                    </div>

                    <div class="col-sm-3">
                      <div class="description-block">
                        <h5 class="description-header">
                          {{
                            data_solicitud.tipo_vehiculo
                              ? data_solicitud.tipo_vehiculo.nombre
                              : ""
                          }}
                        </h5>
                        <span class="description-text text-secondary"
                          >Tipo Vehiculo</span
                        >
                      </div>
                    </div>
                    <div class="col-sm-3">
                      <div class="description-block">
                        <h5 class="description-header">
                          {{ data_solicitud.fecha_hora_inicio_servicio }}
                        </h5>
                        <span class="description-text text-secondary"
                          >Fecha Inicio</span
                        >
                      </div>
                    </div>
                    <div class="col-sm-3">
                      <div class="description-block">
                        <h5 class="description-header">
                          {{ data_solicitud.fecha_hora_fin_servicio }}
                        </h5>
                        <span class="description-text text-secondary"
                          >Fecha Fin</span
                        >
                      </div>
                    </div>
                    <div class="col-3 col-sm-10">
                      <div class="description-block">
                        <h5
                          class="description-header"
                          v-if="data_solicitud.funcionario_gerente"
                        >
                          {{ data_solicitud.funcionario_gerente.nombres }}
                          {{
                            data_solicitud.funcionario_gerente.apellidos
                          }}
                        </h5>
                        <span class="description-text text-secondary"
                          >Gerente de Area</span
                        >
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="description-block">
                        <h5
                          v-if="data_solicitud.origen_JSON"
                          class="description-header"
                        >
                          {{ data_solicitud.origen_JSON }}
                        </h5>
                        <h5
                          v-if="data_solicitud.direccion_origen"
                          class="description-header"
                        >
                          <span>{{
                            data_solicitud.direccion_origen
                          }}</span>
                        </h5>
                        <h5
                          v-if="
                            data_solicitud.lat_origen ||
                              data_solicitud.long_origen
                          "
                          class="description-header"
                        >
                          <small>
                            Lat. {{ data_solicitud.lat_origen }} | Lng.
                            {{ data_solicitud.long_origen }}
                          </small>
                        </h5>
                        <span class="description-text text-secondary"
                          >Origen</span
                        >
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="description-block">
                        <h5
                          v-if="data_solicitud.destino_JSON"
                          class="description-header"
                        >
                          {{ data_solicitud.destino_JSON }}
                        </h5>
                        <h5
                          v-if="data_solicitud.direccion_destino"
                          class="description-header"
                        >
                          <span>{{
                            data_solicitud.direccion_destino
                          }}</span>
                        </h5>
                        <h5
                          v-if="
                            data_solicitud.lat_destino ||
                              data_solicitud.long_destino
                          "
                          class="description-header"
                        >
                          <small>
                            Lat. {{ data_solicitud.lat_destino }} | Lng.
                            {{ data_solicitud.long_destino }}
                          </small>
                        </h5>
                        <span class="description-text text-secondary"
                          >Destino</span
                        >
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="description-block">
                        <h5
                          class="description-header"
                          v-if="data_solicitud.nEstadoOperacion"
                        >
                          {{ data_solicitud.nEstadoOperacion }}
                        </h5>
                        <span class="description-text text-secondary"
                          >Tipo Operacion</span
                        >
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="description-block">
                        <h5
                          class="description-header"
                          v-if="data_solicitud.sitio"
                        >
                          {{ data_solicitud.sitio.nombre }}
                        </h5>
                        <span class="description-text text-secondary"
                          >Sitio</span
                        >
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="description-block">
                        <h5
                          class="description-header"
                          v-if="data_solicitud.tep_solicitud_padre_id"
                        >
                          {{ data_solicitud.tep_solicitud_padre_id }}
                        </h5>
                        <span class="description-text text-secondary"
                          >ID Solicitud Padre</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-body table-responsive">
            <div class="row">
              <table class="table table-bordered table-striped table-sm text-nowrap">
                <thead class="table-dark">
                  <tr>
                    <th>#</th>
                    <th>Usuario</th>
                    <th>Origen</th>
                    <th>Destino</th>
                    <th>Tipo de Vehículo</th>
                    <th>Inicio Servicio</th>
                    <th>Fin Servicio</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in sol_lista" :key="item.id">
                    <td class="text-center">
                      <div class="custom-control custom-radio">
                        <input
                          class="custom-control-input"
                          type="radio"
                          :id="`customRadio${item.id}`"
                          name="customRadio"
                          @change="getSolicitud(item)"
                        >
                        <label :for="`customRadio${item.id}`" class="custom-control-label">{{item.id}}</label>
                      </div>
                    </td>
                    <td :class="!item.users[0] ? 'text-secondary' : ''">
                      {{item.users[0] ? item.users[0].name : 'No tiene usuario'}}
                    </td>
                    <td class="text-wrap">{{ item.direccion_origen }}</td>
                    <td class="text-wrap">{{ item.direccion_destino }}</td>
                    <td>{{ item.tipo_vehiculo ? item.tipo_vehiculo.nombre : '' }}</td>
                    <td class="text-center text-wrap">{{ item.fecha_hora_inicio_servicio }}</td>
                    <td class="text-center text-wrap">{{ item.fecha_hora_fin_servicio }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click="edit()"
              v-if="!$v.form.$invalid"
            >
              Seleccionar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { required } from "vuelidate/lib/validators";

export default {
  name: "TepSolicitudAsignarAOtraSolicitud",
  components: {},
  data() {
    return {
      data_solicitud: {},
      sol_inicial: {},
      sol_lista: {},
      form: {},
    };
  },
  validations: {
    form: {
      required,
    },
  },
  methods: {
    async llenar_modal(item) {
      this.$parent.cargando = true;
      this.form = null;
      this.sol_inicial = {};
      this.sol_lista = {};
      this.data_solicitud = item;
      let params = {
        user_id: item.user_id,
        fecha_hora_fin_servicio: item.fecha_hora_fin_servicio,
        tipo_vehiculo_id: item.tipo_vehiculo_id,
        id: item.id,
      } 

      axios.get("/api/tep/solicitudes/getasignados", {
        params: params,
      })
        .then((response) => {
          this.$parent.cargando = false;
          this.sol_inicial = response.data.solicitud_inicial[0];
          this.sol_lista = response.data.solicitudes_lista;
        })
        .catch((error) => {
          this.$parent.cargando = false;
          this.$swal({
            icon: "error",
            title: "Ocurrió un error " + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },
    getSolicitud(item){
      this.form = item;
    },
    edit(){
      this.$refs.closeModal.click();
      let solicitudes = [];
      solicitudes.push(this.sol_inicial);
      solicitudes.push(this.form);

      return this.$router.push({
        name: "/Tep/SolicitudesNacionalesForm",
        params: {
          data_edit: solicitudes,
          id: solicitudes.id,
        },
      });
    }
  },
};
</script>
